.sideBar{
  position: absolute;
  width:60px;
  height:100vh;
  z-index: 100;
  background-color: rgba(0,0,0, .8);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;

}

.dragText{
  color:rgb(200,200,200);
  font-size: 10px;
  margin:5px;
  user-select: none;
}


.thumbWorldHolder {
  width:50px;
  height:50px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;


  border:1px solid white;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
    pointer-events: auto;
}

.t1{
   background-image: url("./img/bg1.jpg");
}
.t2{
   background-image: url("./img/bg2.jpg");
}
.t3{
   background-image: url("./img/bg3.jpg");
}
.t4{
   background-image: url("./img/bg4.jpg");
}
.t5{
   background-image: url("./img/bg5.jpg");
}
.t6{
   background-image: url("./img/bg6.jpg");
}
.t7{
   background-image: url("./img/bg7.jpg");
}
.t8{
   background-image: url("./img/bg8.jpg");
}
.t9{
   background-image: url("./img/bg9.jpg");
}
